/**
 * this function is to convert markdown to html
 * @function mdToHtml
 ** @param {string} default parameter
 */
export function mdToHtml(str) {
  let tempStr = str;

  if (str[0] === "-") {
    const list = str.substring(2);
    tempStr = `<li>${list}</li>`;
  } else if (str.substring(0, 4) === "####") {
    const list = str.substring(5);
    tempStr = `<h4>${list}</h4>`;
  } else if (str.substring(0, 3) === "###") {
    const list = str.substring(4);
    tempStr = `<h3>${list}</h3>`;
  } else if (str.substring(0, 2) === "##") {
    const list = str.substring(3);
    tempStr = `<h2>${list}</h2>`;
  } else if (str.substring(0, 1) === "#") {
    const list = str.substring(2);
    tempStr = `<h1>${list}</h1>`;
  }
  const regexMatch = "[(.*)]+((.*))";

  if (tempStr.match(regexMatch)) {
    while (tempStr.indexOf("[") !== -1) {
      const firstPos = tempStr.indexOf("[");
      const nextPos = tempStr.indexOf("]", firstPos + 1);
      const firstPosPos = tempStr.indexOf("(", nextPos + 1);
      const nextPosPos = tempStr.indexOf(")", firstPosPos + 1);

      const link = `<a href='${tempStr.substring(
        firstPosPos + 1,
        nextPosPos
      )}'>${tempStr.substring(firstPos + 1, nextPos)}</a>`;

      tempStr =
        tempStr.substring(0, firstPos) +
        link +
        tempStr.substring(nextPosPos + 1, tempStr.length);
    }
  }
  while (tempStr.indexOf("**") !== -1) {
    const firstPos = tempStr.indexOf("**");
    const nextPos = tempStr.indexOf("**", firstPos + 2);
    if (nextPos !== -1) {
      const innerTxt = tempStr.substring(firstPos + 2, nextPos);
      const bold = `<b>${innerTxt}</b>`;
      tempStr =
        tempStr.substring(0, firstPos) +
        bold +
        tempStr.substring(nextPos + 2, tempStr.length);
      // removing unclosed '**'
    } else {
      tempStr = tempStr.replace("**", "");
    }
  }
  while (tempStr.indexOf("__") !== -1) {
    const firstPos = tempStr.indexOf("__");
    const nextPos = tempStr.indexOf("__", firstPos + 2);
    if (nextPos !== -1) {
      const innerTxt = tempStr.substring(firstPos + 2, nextPos);
      const strong = `<strong>${innerTxt}</strong>`;
      tempStr =
        tempStr.substring(0, firstPos) +
        strong +
        tempStr.substring(nextPos + 2, tempStr.length);
      // removing unclosed '__'
    } else {
      tempStr = tempStr.replace("__", "");
    }
  }
  while (tempStr.indexOf("_") !== -1) {
    const firstPos = tempStr.indexOf("_");
    const nextPos = tempStr.indexOf("_", firstPos + 1);
    if (nextPos !== -1) {
      const innerTxt = tempStr.substring(firstPos + 1, nextPos);
      const italicized = `<i>${innerTxt}</i>`;
      tempStr =
        tempStr.substring(0, firstPos) +
        italicized +
        tempStr.substring(nextPos + 1, tempStr.length);
      // removing of unclosed '_'
    } else {
      tempStr = tempStr.replace("_", "");
    }
  }
  return tempStr;
}

/**
 * this function returns array of string after converting markdown into html
 * @function formatDescriptionDatal
 ** @param {string} default parameter
 */
export function formatDescriptionData(description) {
  const descriptionPoints = description?.split("\n").filter(Boolean); // split description string by new line and filtering non-empty strings
  return descriptionPoints?.map((e) => {
    return mdToHtml(e.trim());
  });
}
