import React, { useState, useEffect, useReducer } from "react";
import { SpinnerCircular } from "spinners-react";
import { useNavigate } from "@reach/router";
import Helmet from "react-helmet";
import Header from "../header";
import Layout from "../layout";
import Thanks from "./thanks";
import Error from "./error";
import ApplicationForm from "../ApplicationForm";
import { reducer } from "./reducers";
import { formateObject, getData, postData } from "./utils";
import {
  INITIAL_FORM_FIELD_STATE,
  ACTIONS,
  CAPTCHA_ENDPOINT,
} from "./constant";
import { BASE_URL, FILE_UPLOAD } from "../../../constant";
import ThanksMessage from "./thanksMessageOnError";
import { Message } from "../../lib/getMessage";
/**
 * this function is for formatting data for apply form as per requirement of backend
 * @function formateData
 * @param {state} state
 * @returns data as required
 */

export const formateData = (state, id) => {
  return {
    position: { id: Number(id) },
    name: state?.name,
    phone: `+91${state?.phone}`,
    email: state?.email,
    resume: state?.resume?.path,
    links: state?.links,
    noticePeriod: state?.noticePeriod,
    additionalInfo: state?.additionalInfo,
    currentSalary: Number(state?.currentSalary),
    expectedSalary: Number(state?.expectedSalary),
    currentLocation: state?.currentLocation,
  };
};

/**
 * loader for fetch data
 */
const dataLoading = {
  resumeLoading: false,
  tokenLoading: false,
};

const Apply = ({ id, language, location, updateLanguage, direction }) => {
  const lang = language ? `/${language}` : "";
  const [state, dispatch] = useReducer(reducer, INITIAL_FORM_FIELD_STATE);
  const [applied, setApplied] = useState({ status: false, message: "" });
  const [error, setError] = useState({ status: false, message: "" });
  const [isServerError, setIsServerError] = useState(false);
  const [positionDescriptionStatus, setPositionDescriptionStatus] = useState(
    false
  );
  const [formSubmit, setFormSubmit] = useState(false);
  const [loading, setLoading] = useState(dataLoading);
  const [token, setToken] = useState("");
  const [positionDescription, setPositionDescription] = useState(undefined);

  const navigate = useNavigate();

  /**
   * this function is taken care of setting error and updating parameter for all field of application form
   * @function handleApplicationFormFields
   * @param {event,index} event parameter
   */

  const handleApplicationFormFields = (event) => {
    const { name, value } = event.target;
    dispatch(formateObject(name, value));
  };

  /**
   * this function is taken care of handling additional Info , validate and setting error according
   * @function handleAdditionalInfo
   * @param {item} default parameter
   */
  const handleAdditionalInfo = (item) => {
    dispatch({ type: ACTIONS.ADDITIONAL_INFO, payload: { value: item } });
  };

  /**
   * this function is taken care of handling resume
   * @function handleResume
   * @param {event} default parameter
   */

  const handleResume = (event) => {
    event.preventDefault();
    let resumeValue = event.target.files && event.target.files[0];
    if (!resumeValue)
      resumeValue = event?.dataTransfer?.files && event?.dataTransfer?.files[0];
    if (
      resumeValue.type !== "image/jpeg" &&
      resumeValue.type !== "image/webp" &&
      resumeValue.type !== "image/png" &&
      resumeValue.type !== "application/pdf" &&
      resumeValue.type !== "application/msword" &&
      resumeValue.type !==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setError((prevValues) => {
        return {
          ...prevValues,
          status: true,
          message: <Message dictKey="err.upload.doc.pdf" />,
        };
      });
    } else if (resumeValue) {
      setLoading((prevLoading) => {
        return { ...prevLoading, resumeLoading: true };
      });
      const url = `${BASE_URL}${FILE_UPLOAD}`;
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        })
        .then((data) => {
          const uploadURL = data.data["upload-url"];
          const file = new Blob([resumeValue], {
            type: resumeValue.type,
          });
          fetch(uploadURL, {
            method: "PUT",
            body: file,
          }).then((response) => {
            if (response.ok) {
              setLoading((prevLoading) => {
                return { ...prevLoading, resumeLoading: false };
              });
              dispatch({
                type: ACTIONS.RESUME,
                payload: {
                  value: data?.data?.url,
                  name: resumeValue.name,
                },
              });
            }
          });
        })
        .catch((error) => {
          setLoading((prevLoading) => {
            return { ...prevLoading, resumeLoading: false };
          });
          setError((prevValues) => {
            return {
              ...prevValues,
              status: true,
              message: <Message dictKey="err.upload.again" />,
            };
          });
        });
    }
  };

  /**
   * this function is taken care of handling recaptcha
   * @function handleCaptcha
   * @param {value} default parameter
   */
  const handleCaptcha = (value) => {
    dispatch({
      type: ACTIONS.RECAPTCHA,
      payload: { value },
    });
  };

  useEffect(() => {
    setLoading((prevLoading) => {
      return { ...prevLoading, tokenLoading: true };
    });
    fetch(`${BASE_URL}${CAPTCHA_ENDPOINT}`, {
      method: "GET",
      headers: {
        "True-Client-Ip": "127.0.0.1",
        "X-Correlation-ID": 1,
        "X-Zopsmart-Tenant": "Zopsmart",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((token) => {
        setLoading((prevLoading) => {
          return { ...prevLoading, tokenLoading: false };
        });
        setToken(token.data.access_token);
        if (!token.data.access_token.length) {
          return Promise.reject(token);
        }
      })
      .catch((error) => {
        setLoading((prevLoading) => {
          return { ...prevLoading, tokenLoading: false };
        });
        navigate(`${lang}/careers`, {
          state: { tokenError: true, positionId: id },
        });
      });
  }, []);

  useEffect(() => {
    getData(setPositionDescription, setPositionDescriptionStatus, id);
  }, [id]);

  function handleCancel() {
    navigate(`${lang}/careers`);
  }
  function handleSubmitForApplicationForm() {
    setFormSubmit(true);
    const reqBody = formateData(state, positionDescription?.data?.id);
    postData(
      setApplied,
      setFormSubmit,
      setError,
      setIsServerError,
      state.recaptcha,
      reqBody
    );
  }

  useEffect(() => {
    if (error.status) {
      handleCaptcha("");
    }
  }, [error.status]);

  if (!positionDescription && positionDescriptionStatus)
    navigate(`${lang}/careers`, {
      state: { tokenError: true, positionId: id },
    });
  return (
    <Layout language={language} location={location} direction={direction}>
      <Helmet
        title={`${
          positionDescription?.data?.title
            ? positionDescription.data.title
            : "Find a job"
        } | Careers at ZopSmart`}
      />
      <Header
        current="careers"
        language={language}
        updateLanguage={updateLanguage}
        direction={direction}
      />
      {isServerError && (
        <ThanksMessage
          handleClearError={() => setIsServerError(false)}
          message={positionDescription?.data?.title}
        />
      )}
      {error.status && !formSubmit && !applied.status && (
        <Error errorMessage={error.message} setError={setError} />
      )}
      {applied.status ? (
        <Thanks thanksMessage={applied.message} />
      ) : !positionDescription || formSubmit || loading.tokenLoading ? (
        <div className="error-404">
          <SpinnerCircular
            size={90}
            thickness={100}
            speed={100}
            color="#00b6a4"
            secondaryColor="rgba(0, 0, 0, 0.44)"
          />
        </div>
      ) : (
        <ApplicationForm
          state={state}
          loading={loading}
          positionDescription={positionDescription}
          handleApplicationFormFields={handleApplicationFormFields}
          handleAdditionalInfo={handleAdditionalInfo}
          handleResume={handleResume}
          handleCaptcha={handleCaptcha}
          handleSubmit={handleSubmitForApplicationForm}
          handleCancel={handleCancel}
        />
      )}
    </Layout>
  );
};

export default Apply;
