import React from "react";

class PositionFilter extends React.Component {
  render() {
    const { current, filterFunc, allPositions = [] } = this.props;

    return (
      <div className="job-filter-wrapper">
        <div>
          <div className="job-filter">
            {allPositions.map(({ title }, index) => (
              <div
                key={`${title}-${index}`}
                className={
                  "job-filter-elm" +
                  (current === title ? " active-job-filter" : "")
                }
                onClick={filterFunc(title)}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
              >
                {title}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default PositionFilter;
